<template>
  <div style="height: inherit">

    <b-embed
      v-if="urlViewer"
      type="iframe"
      :src="urlViewer"
      allowfullscreen
    />

  </div>
</template>

<script>
import {
  computed, ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import {
  BEmbed,
} from 'bootstrap-vue'
import atob from 'atob'
import { mapGetters } from 'vuex'

export default {
  components: {
    BEmbed,
  },
  computed: {
    ...mapGetters('auth', ['toolboxLinks']),
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(to) {
        this.getLoadPage(to.params)
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const oInfolink = vm.checkUrlAllow(to.params)
      if (oInfolink === '') {
        next('/')
      }

      vm.getLoadPage(to.params)
    })
  },
  methods: {
    checkUrlAllow(params) {
      if ((this.toolboxLinks !== null) && (this.toolboxLinks !== undefined) && (this.toolboxLinks.length > 0)) {
        const oInfoLink = this.toolboxLinks.find(o => o.sw047s01 === atob(params.id))
        if (oInfoLink !== undefined) {
          return oInfoLink
        }
      }

      return ''
    },
    getLoadPage(paramsUrl) {
      if ((paramsUrl !== null) && (paramsUrl !== undefined) && ('id' in paramsUrl) && (paramsUrl.id !== '')) {
        const oInfoLink = this.checkUrlAllow(paramsUrl)
        if (oInfoLink !== '') {
          this.urlViewer = oInfoLink.sw047s03
        }
      }
    },
  },
  setup() {
    const { route } = useRouter()
    const routeParams = computed(() => route.value.params)
    const urlViewer = ref('')

    return {
      routeParams,
      urlViewer,
    }
  },
}
</script>
